import React from 'react';
import { Container, Col, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import _ from "lodash"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetGGFXImage from '../common/site/get-ggfx-image';
import { ImageModule } from "../common/Image_Module"
import './assets/styles/_index.scss';
import clsx from 'clsx';
import NoImg from "../../images/no-image.png"

const BeInspiredSlider = ({module}) => {

    // Offplan sales
    const DATA_OFFPLAN = gql`
        query GetProperty {
            offplans(
                limit: 6
                sort: "price:DESC"
                where:{search_type: "sales", publish: "true"}) {
                id
                area
                display_address
                slug
                search_type
                publish
                price
                images
                imagetransforms
                department
                description
            }
        }
    `;

    const { data: dataOffplan } = useQuery(DATA_OFFPLAN);
    // Offplan sales

    const usePropertyQuery = (searchType, department) => {
        const QUERY = gql`
            query GetProperty {
                properties (
                    limit: 6
                    sort: "price:DESC"
                    where:{search_type: "${searchType}", publish: "true", department: "${department}"}) {
                    id
                    area
                    display_address
                    slug
                    search_type
                    publish
                    price
                    currency
                    images
                    imagetransforms
                    ggfx_results {
                        transforms
                        src_cftle
                    }
                    description
                    department
                }
            }
        `;
        const { data } = useQuery(QUERY);
        return data;
    };

    // Sales
    const dataSales = usePropertyQuery("sales", "residential");

    // Lettings
    const dataLettings = usePropertyQuery("lettings", "residential");

    // Holiday Homes
    const dataholidayHomes = usePropertyQuery("lettings", "short_lets");

    // Commercial - Sales
    const dataCommercial = usePropertyQuery("sales", "commercial");

    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    const tabData = [
        { key: 'for-sale', title: 'For Sale', data: dataSales?.properties },
        { key: 'for-rent', title: 'For Rent', data: dataLettings?.properties },
        // { key: 'off-plan', title: 'Off Plan', data: dataOffplan?.offplans },
        { key: 'holiday-homes', title: 'Holiday Homes', data: dataholidayHomes?.properties },
        { key: 'commercial', title: 'Commercial', data: dataCommercial?.properties },
    ];

    // get property detail url
    const getPropertyUrl = (item) => {
        const urlMapping = {
            off_plan: '/off-plan-property',
            commercial: '/commercial-property',
            short_lets: '/holiday-homes',
            residential: '/property'
        };

        const department = item.department || 'residential';
        const searchType = item.search_type || 'rent';
        const urlBase = urlMapping[department] || urlMapping.residential;

        const url = `${urlBase}-${searchType === 'sales' ? 'for-sale' : 'for-rent'}/${item.slug}-${item.id}/`;

        return url;
    }

    const renderSliderContent = (properties) => {
        return (
            <Slider className="be-inspired-slider" {...settings} infinite={true}>
                {properties?.map((item, key) => {
                    let processedImages = JSON.stringify({});
                    if (item?.imagetransforms?.images_Transforms) {
                        processedImages = item.imagetransforms.images_Transforms;
                    }

                    return (
                        <div className="be-inspired-slide" key={key}>
                            <div className="be-inspired-img-zoom">
                                <Link to={getPropertyUrl(item)}>
                                    {!_.isEmpty(item?.images) ? (
                                        <ImageModule
                                            imagename={"property.images.featuredProperties"}
                                            ImageSrc={item?.images[0]}
                                            ggfx_results={item?.ggfx_results}
                                            altText={item?.display_address}
                                            strapi_id={item?.id}
                                        />
                                    ) : (
                                        <img src={NoImg} alt="no_img" />
                                    )}
                                </Link>
                            </div>
                            <div className="be-inspired-text-wrapper">
                                <p className="display-address display-desc">{item?.description}</p>
                                <div className="price-details">{item?.price.toLocaleString()} {item?.currency}</div>
                                <p className="display-address">{item?.display_address}</p>
                                <div className="location-details d-flex align-items-center">
                                    <i className="icon icon-map-pin"></i>
                                    <span>{item?.area}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        );
    };

    return (
        <section className={clsx('be-inspired-slider-wrapper section-p', {'bg-gray': module?.theme === 'gray'})}>
            <Container>
                <Row>
                    <Col className="text-center">
                        <p className="small-header-text">{module?.title ? module?.title : "Be Inspired"}</p>
                        <h2>{module?.heading ? module?.heading : "Handpicked Properties"}</h2>
                        <Tabs
                            defaultActiveKey="for-sale"
                            className="be-inspired-tab d-flex justify-content-center"
                        >
                        {tabData?.map(({ key, title, data }) => (
                            !_.isEmpty(data) && (
                                <Tab key={key} eventKey={key} title={title}>
                                    {renderSliderContent(data)}
                                </Tab>
                            )
                        ))}
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BeInspiredSlider