import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import AreaguideCard from "../AreaguideCard/AreaguideCard"
import Pagination from "../Pagination/Pagination"
import "./AreaguideListing.scss"

const AreaguideListing = () => {
  const data = useStaticQuery(graphql`
    query GetAreaguideListingData {
      glstrapi {
        areaGuides(where: {publish: true}, sort: "sort:asc") {
          name
          id
          slug
          sort
          short_description
          tile_image {
            url
          }
          ggfx_results {
            src_cftle
            transforms
          }
        }
      }
    }
  `)

  const areaguides = data.glstrapi.areaGuides

  const { isMobile, isTablet } = useDeviceMedia()

  const itemsPerPage = isMobile ? 8 : isTablet ? 12 : 16
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: areaguides,
    itemsPerPage,
  })

  return (
    <Container className="areaguide-wrap">
      <div className="areaguide-container">
        {currentItems.map(areaguide => (
          <AreaguideCard areaguide={areaguide} />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={areaguides.length}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  )
}

export default AreaguideListing
